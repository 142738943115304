.tutorials-video-list {
  margin-right: 5px;
  padding: 0;
  width: 98%;
}

.tutorials-video-item {
  margin: 15px 0;
  padding: 9px;
  border-radius: 10px;
  box-shadow: 0.5em 0.5em 1em rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  position: relative;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.step-icon-container {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-icon-img {
  width: 30px;
  height: 30px;
}

.custom-padding-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
}

.custom-paginator {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

@media (max-width: 768px) {
  .tutorials-video-item {
      flex-direction: column;
      margin: 15px 0;
      padding: 10px;
  }
  .step-indicator {
      display: none;
  }
  .step-icon-container {
      padding: 8px;
  }
  .custom-padding-left {
      margin-left: 0;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
  }
}
@media (min-width: 1024px) {
  .tutorials-video-list {
    margin-left: -35px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .tutorials-video-item {
    margin: 20px 0;
    padding-left: 0px !important;
    flex-direction: row;
  }

  .step-icon-container {
    width: 40px;
    height: 40px;
  }
}
