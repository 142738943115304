.toolbar-left {
  display: flex;
  flex-direction: column;
}

.toolbar.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.toolbar-title {
  border: solid 1px #80808078;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.toolbar-content {
  overflow-x: scroll;
  height: 320px;
}

.toolbar-contentdata {
  display: flex;
  margin-top: 15px;
}

.custom-poitions,
.custom-poitions-subtitle,
.custom-poitions-title {
  border: solid 1px #80808078;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  min-width: 130px;
  width: fit-content;
}

.custom-poitions-subtitle {
  background-color: var(--tempedge-blue);
  color: white;
}

.custom-poitions-title {
  background-color: var(--tempedge-gray);
}
