.range-blue {
  width: 100%; /* Ocupa todo el ancho padre, si deseas */
  height: 8px; /* Alto de la pista */
  background: #cbe6f5; /* Azul muy claro para la pista */
  border-radius: 4px; /* Redondea la pista */
  outline: none; /* Quita contorno */
  cursor: pointer;
  /* Podrías aplicar un pequeño shadow si quieres */
}

