.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #5abeec;
    min-height: 50px;
    color: white;
    border: 2px solid #5abeec;
    padding: 12px 40px 12px 40px;
    display: flex;
    align-items: center;;
}

.nav-tabs {
    border: none;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.nav-tabs .nav-item {
    background-color:  #888888;
    min-height: 50px;
    color: white;
    margin-right: 0.5rem;
    padding: 12px 40px;
    display: flex;
    align-items: center
}

.nav-tabs .nav-error{
   border: 1px solid red !important;
}

.nav-tabs .nav-item:last-child {
    border-top-right-radius: 1.6rem;
}

.tab {
    border-radius: 0 1.6rem 1.6rem ;
    border: 1px dotted #888888;
    padding: 3rem;
    width: 100%;
}

.tab-candidate {
    border-radius: 0 0 1.6rem 1.6rem ;
    border: 1px dotted #888888;
    padding: 3rem;
    width: 100%;
}

.nav-tabs .nav-item:first-child {
   border-top-left-radius: 1.6rem;
}

.nav-tabs .nav-link:hover:not(.active){
    color: var(--tempedge-light-orange);
}

.nav-misc{
    border-right:  1px solid #d7d7d7
}

@media (max-width: 768px){
    .nav-misc{
        border-right:  none
    }
    
}