.positive-variation {
    color : red;
}

.negative-variation {
    color : green
}

.table-variation-style {
    font-size: 16px;
    line-height: 24px; 
    font-weight: 400;
    text-align: right;
    text-wrap : wrap;
}

.t-head-style {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 20px;
    margin-bottom: .4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-no-wrap {
    text-wrap: nowrap;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-truncate {
    direction: rtl;
}

.table-first-td-style {
    max-width: 200px;
    font-weight: 500; 
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
    overflow: hidden;
}

.card-title {
    padding-block: 10px; 
    padding-bottom: 20px;
    text-wrap: wrap;
    text-align: center;
}

.span-data-card {
    color: var(--tempedge-black);
    font-weight: normal;
}

.bg-inherit {
    background-color: inherit;
}

.dotted-border {
    border-top: dotted 1px #626262;
}

.table-container { 
    height: 330px; 
    overflow: auto; 
    width : 100%; 
}

.table-header-sticky {
    position : sticky;
    top : 0;
    z-index: 1;
    background-color: var(--tempedge-white);
}