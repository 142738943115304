:root {
  --tempedge-white: #fff;
  --tempedge-blue: #3eb7e9;
  --tempedge-dark-blue: #0088cc;
  --tempedge-light-blue: #69c8f4;
  --tempedge-orange: #eb8d34;
  --tempedge-green: #8cb544;
  --tempedge-gray-darken-2: #c2c2c2;
  --tempedge-gray-darken-3: #949494;
  --tempedge-gray: #d7d7d7;
  --tempedge-light-orange: #ffae64;
  --tempedge-gray-lighten-1: #e9ecef;
  --tempedge-gray: #bdbdbd;
  --tempedge-dark-gray: #888;
  --tempedge-aquamarine: #44D9AF;
  --tempedge-yellow: #FED235;
  --success-result-bar-bkg-color: #98fb98;
  --tempedge-light-bar-bkg-color: #e0f5e0;
  --success-result-bar-font-color: #008000;
  --error-result-bar-bkg-color: #ff9999;
  --error-result-bar-font-color: #ff0000;
}

