@import url('https://fonts.googleapis.com/css2?family=Nexa:wght@400;700&family=Quicksand:wght@400;700&display=swap');

.text-with-logo {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.temp-text {
  font-family: 'Nexa', sans-serif;
  font-style: normal;
  font-weight: 780;
}

.edge-text {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
}

.rounded-logo {
  position: absolute;
  top: -5px;
  left: 100%;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: opacity 0.3s ease;
}
