.time-sheet {
  padding: 2rem;
  margin: 28px 0;
  border-radius: 1.5rem;
  box-shadow: inset 0 1px 6px 0 #b5b5b5;
}

.time-sheet__header {
  padding-bottom: 2rem;
  border-bottom: 1px dashed var(--tempedge-gray);
}

.time-sheet__body {
  padding-top: 2rem;
}



