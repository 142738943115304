.switch {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.switch-sm {
  height:28px;
  min-width: 50px;
  max-width:74px;
}

.switch-md {
  height:36px;
  min-width: 74px;
  max-width: 100px;
}

.switch-error {
  border:1px solid red;
}

.toggle-switch__checkbox {
  cursor: pointer;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: relative;
  padding: 0;
  width: 100%;
  z-index: 1;
}

.toggle-switch__knobs {
  z-index: 1;
}

.toggle-switch__layer {
  background-color: white;
  border-radius: 14px;
  border: 1px solid #dadada;
  width: 100%;
  z-index: 0;
}

.toggle-switch__layer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.switch .toggle-switch__knobs {
  background-color: #888888;
  border-radius: 14px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  height: 100%;
  right: 0%;
  padding: 12px 4px;
  position: absolute;
  text-align: center;
  top: 0px;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch .toggle-switch__knobs p {
  margin:  0;
}

.switch .toggle-switch__checkbox:checked + .toggle-switch__knobs {
  background-color: #3eb7e9;
  /* content: 'Yes'; */
  right: 40%;
  width: 65%;
}

.switch .toggle-switch__checkbox:checked ~ .toggle-switch__layer {
  background-color: white;
}