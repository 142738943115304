.paginator-button {
  border-radius: 25px;
  margin: 0 3px;
  color: #FFF;
}

.paginator-button-prev {
  background-color: #888888;
}

.paginator-button-next {
  background-color: #0088CC;
}

.paginator-button-disabled {
  opacity: 0.5;
}

.paginator-page-button {
  border-radius: 50px;
  margin: 0 3px;
  background-color: #888888;
  color: #FFF !important;
}

.paginator-page-button-active {
  background-color: #0088CC;
}
