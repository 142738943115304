.container-dashboard {
   width: 100%;
   margin: 0 auto;
}
.card-report {
  background-color: #fff;
  border-radius: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
}