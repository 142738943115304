.tutorials-sidebar-nav {
    background-color: white;
    width: 100%;
    max-width: 200px;
    padding-top: 20px;
    margin: 0;
    overflow-y: auto;
    position: sticky;
    top: 0;
    height: auto;
    max-height: calc(100vh - 20px);
}

.tutorials-sidebar-nav::before {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    bottom: 0;
    width: 1px;
    background-color: #ccc;
    transform: translateX(-5px);
}

.tutorials-sidebar-nav .nav-pills .tutorials-nav-link {
    color: #333;
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 4px;
    display: block;
    transition: all 0.3s ease;
    background-color: transparent;
    margin-top: 15px;
    position: relative;
}

.tutorials-sidebar-nav .nav-pills .tutorials-nav-link.active {
    color: #020202;
    background-color: transparent;
    border-left: 4px solid #5abeec;
    padding-left: 12px;
}

.tutorials-sidebar-nav .nav-pills .tutorials-nav-link:hover {
    background-color: #f1f1f1;
    color: #5abeec;
}

.tutorials-sidebar-nav .nav-pills .nav-item:last-child .tutorials-nav-link {
    border-bottom: none;
}

@media (max-width: 768px) {

    .tutorials-sidebar-nav::before {
        display: none;
    }
    
    .tutorials-sidebar-nav {
        max-width: 100%;
        height: auto;
        border-right: none;
        border-bottom: 1px solid #ccc;
        padding: 6px 0;
    }

    .tutorials-sidebar-nav .nav-pills .tutorials-nav-link {
        margin: 0 5px;
        padding: 8px 10px;
        font-size: 0.9em;
        position: relative;
    }

    .tutorials-sidebar-nav .nav-pills .tutorials-nav-link.active {
        border-left: 4px solid #5abeec;
        border-bottom: none;
        padding-left: 12px;
    }
}
