.react-datepicker__current-month {
  display: none;
  font-size: 1em;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: var(--tempedge-blue);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--tempedge-blue);
  color: white;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  visibility: visible !important;
  color: #565656;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #565656;
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__day--selected {
  color: white;
}
.react-datepicker__header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #dee2e6;
  padding-top: 0.8em;
}
.react-datepicker {
  border: 1px solid #dee2e6;
  box-shadow: 0 1px 5px #ccc;
  font-size: 1em;
}
.react-datepicker-popper.react-datepicker__triangle::before {
  border-bottom-color: #dee2e6;
  margin-top: 0px !important;
}


.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: 0px;
}


.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f7f7f7;
  color: #565656;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__navigation {
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: var(--tempedge-blue);
  color: white;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: var(--tempedge-blue);
}

.react-datepicker-wrapper {
  display: inherit;
}

.react-datepicker-popper {
  z-index: 2;
}

.modal-body .react-datepicker {
  font-size: 0.9em;
}
