@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=DM+Serif+Display&family=Inter:wght@300;400;500;600;700;800&display=swap');

:root {
    --background-1: #0F1410;
    --background-2: #F7F4ED;
    --color-primary: #0FA958;
    --color-1: #000000;
    --color-2: #3F3F3F;
    --border-brutal: #000;
    --border: rgba(0,0,0,0.1);
    --border-2: rgba(0,0,0,0.15);
    --bg-shade-1: #ffffff;
    --bg-shade-blue: #D7CDFF;
    --bg-shade-green: #DCFFDB;
    --bg-shade-yellow: #FFFB9E;
    --bg-shade-pink: #FDE4FF;
    --color-red: #E94057;
    --color-blue: #2C6FEE;
}

.dark {
    --color-1: #fefefe;
    --color-2: #D0D0D0;
    --border: rgba(255,255,255,0.1);
    --border-2: rgba(255,255,255,0.15);
    background: var(--background-1);
    color: var(--color-1);
}
.bg-2{
    background: var(--background-2);
}
.bg-shade-1{
    background: var(--bg-shade-1);
}
.bg-shade-blue{
    background: var(--bg-shade-blue);
}
.bg-shade-green{
    background: var(--bg-shade-green);
}
.bg-shade-yellow{
    background: var(--bg-shade-yellow);
}
.bg-shade-pink{
    background: var(--bg-shade-pink);
}
.color-1{
    color: var(--color-1) !important;
}
.color-2{
    color: var(--color-2) !important;
}
.color-primary{
    color: var(--color-primary) !important;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.fw-800{
    font-weight: 800;
}
.font-primary{
    font-family: 'Inter',sans-serif !important;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color : red;
    opacity : 0.5;
}

.btn-landing {
    overflow: unset;
    text-wrap: nowrap;
    display: inline;
    font-size: larger;
    padding-inline: 50px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.prev-next {
    /*background: black 50% / 100% 100%;*/
    display: block;
    height: 200px;
    width: 25px;
    color: black;
    opacity: 1;
        
}

.carousel-control-prev {
    display: block;
    height: 200px;
    width: 25px;
    color: black;
    opacity: 1;
}

.carousel-control-next {
    display: block;
    height: 200px;
    width: 25px;
    color: black;
    opacity: 1;
}

.play-icon-circle {
    height: 64px;
    width: 64px;
    border-radius: 50%;    
    color: #FF0000;
    font-size: 28px;    
  }

.card-container {
    display: inline-block; /* Ensures container size adjusts to content */
    border: 1px solid var(--border-brutal); /* Border style */
    border-right: 8px solid var(--border-brutal);
    border-bottom: 8px solid var(--border-brutal);
    border-radius: 5%;
    padding: 25px;
    background-color: white; /* Background color with transparency */
}

.card-video-container {
    min-width: 300px;
    max-width: 300px;
    display: inline-flex;
    min-height: 220px;
    flex-direction: column;        
    justify-content: space-around;    
    border-right: 4px solid var(--border-brutal);
    border-bottom: 4px solid var(--border-brutal); 
    
    
}

.card-video-container h3 {
    font-weight: 400;
}

.image-container {
    padding: 0px; 
    opacity: 0.75;/* Background color with transparency */
}

/* CONTAINERS AND SECTIONS */
.stact-container {
    padding-top: 70px;
}

@media (max-width: 991.98px){
    .stact-container {
        padding-top: 56px;
    }
}

.section-global {
    padding: 60px 0;
    border-bottom: 1px solid var(--border-brutal);
}

@media (max-width: 991.98px) {
    .section-global {
        padding: 100px 0;
    }
}

@media (max-width: 767.98px) {
    .section-global {
        padding: 80px 0;
    }
}

@media (max-width: 575.98px) {
    .section-global {
        padding: 60px 0;
    }
}

.section-global.no-border {
    border-bottom: 0;
}

/* TYPOGRAPHY */
.large-text {
    color : white;
    font-size: calc(1.575rem + 3.9vw);
    line-height: 1.15;
    font-family: 'DM Serif Display', sans-serif;
}

@media (min-width: 1200px) {
    large-text {
        font-size: 74px;
        line-height: 1;
    }
}

.medium-text {
    display: block;
    margin-left: 0;
    margin-right: 0;
    font-weight: 500;
    font-size: calc(45px);
    line-height: 1.15;
    font-family: 'DM Serif Display', sans-serif;
    color:var(--color-1);
}

@media (min-width: 1200px) {
    medium-text{
        font-size: 40px;
        line-height: 1.15;
    }
}

.display-1 {
    font-family: 'DM Serif Display', sans-serif;
    font-size: calc(1.425rem + 2.1vw);
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-1 {
        font-size: 48px;
        line-height: 1.1;
    }
}

.display-2 {
    font-family: 'DM Serif Display', sans-serif;
    font-size: calc(1.325rem + .9vw);
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-2 {
        font-size: 30px;
    }
}

.small-text {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.35;
}

.label-text-white{
    font-size: 20px;
    font-weight: 700;
    line-height: 1.35;
    color: white;
}

.txt-1 {
    font-size: 20px;
    color: var(--color-1);
    line-height: 1.5;
}

.txt-2 {
    font-size: 18px;
    color: var(--color-1);
    line-height: 1.5;
}

.txt-3 {
    font-size: 16px;
    color: var(--color-1);
    line-height: 1.55;
}

.txt-4 {
    font-size: 14px;
    color: var(--color-1);
    line-height: 1.5;
}

.txt-highlight {
    color: var(--color-red);
}

.section-tag {
    font-family: 'Caveat', sans-serif;
    font-weight: 1400;
    color: #000;
    font-size: 60px;
    padding-bottom: 60px;
    justify-content: center;
}

/* SPACING */
.mb-0 {
    margin-bottom: 0;
}

.mt-0 {
    margin-top: 0;
}

.mb-8 {
    margin-bottom: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}
  
  .bg-overlay-gradient {
    background: #396afc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2948ff,
      #396afc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2948ff,
      #396afc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.4;
  }

  .bg-gradient {
    background: -webkit-linear-gradient(#7474bf, #348ac7);
    background: -moz-linear-gradient(#7474bf, #348ac7);
    background: -ms-linear-gradient(#7474bf, #348ac7);
    background: -o-linear-gradient(#7474bf, #348ac7);
    background: linear-gradient(#7474bf, #348ac7);
  }

  .bg-overlay-gredient {
    background: #1cb5e0; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to left,
      #1cb5e0,
      #000046
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to left,
      #1cb5e0,
      #000046
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
    position: relative;
    height: 100%;
    width: 100%;
    top: 0px;
    opacity: 0.8;
  }
 
  .img-width {
    max-width: '600px'
  }

  .arrow-carousel {
    color : red
  }

  .arrow-carousel-right {
    right: 0;
  }

  .carousel-container-outter {
    display: flex; 
    justify-content: center;
  }

  .carousel-container {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-content: center;
    text-align: center;
  }

  .button-open-modal {
    flex-direction: column;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .label-video-card {
    color: black;
    font-size: 11px;
  }

  .get-started-container { 
    display: flex;
    background-color: #000;
    height: 75px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
  }

  .get-started-elements-container {
    justify-content: space-around;
    align-content: center;
    align-items: center;
    position: static;
  }