:root {
   --input-color: #252626d0;
   --input-border: #cdd9ed;
   --input-background: #fff;
   --input-placeholder: #cbd1dc;
   --input-border-focus: #cbd1dc;
   --group-color: var(--input-color);
   --group-border: var(--input-border);
   --group-background: #e9ecef;
   --group-color-focus: #fff;
   --group-border-focus: var(--input-border-focus);
   --group-background-focus: #b6b7b7;
}

.form-field-payroll  {
   display: block;
   width: 100%;
   padding: 6px 8px;
   line-height: 12px;
   font-size: 10px;
   font-weight: normal;
   font-family: inherit;
   border-radius: 6px;
   -webkit-appearance: none;
   appearance: none;
   color: var(--input-color);
   border: 1px solid var(--input-border);
   background: var(--input-background);
   transition: border 0.3s ease;
   max-width: 190px;
}
.form-field-payroll::placeholder {
   color: var(--input-placeholder);
}
.form-field-payroll:focus {
   outline: none;
   border-color: var(--input-border-focus);
}

.form-group-payroll {
   position: relative;
   display: flex;
   width: 100%;
   max-width: 190px;
   margin: auto;
}
.form-group-payroll > span,
.form-field-payroll {
   white-space: nowrap;
   display: block;
}
.form-group-payroll > span,
.form-group-payroll .form-field-payroll:not(:first-child):not(:last-child) {
   border-radius: 0;
}
.form-group-payroll .form-field-payroll:first-child {
   border-radius: 6px 0 0 6px;
}
.form-group-payroll .form-field-payroll:last-child {
   border-radius: 0 6px 6px 0;
}
.form-group-payroll > span {
   width: 40%;
   text-align: center;
   padding: 8px 4px;
   font-size: 9px;
   line-height: 12px;
   color: var(--group-color);
   background: var(--group-background);
   border: 1px solid var(--group-border);
   transition:
      background 0.3s ease,
      border 0.3s ease,
      color 0.3s ease;
}
.form-group-payroll:focus-within > span {
   color: var(--group-color-focus);
   background: var(--group-background-focus);
   border-color: var(--group-border-focus);
}

  
  @media (max-width: 1200px) {
    .form-group-payroll {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .form-group-payroll > span {
      width: 30%;
      font-size: 7px;
      padding: 4px 2px;
    }
    .form-field-payroll {
      width: 70%;
      font-size: 8px;
      padding: 4px 5px;
    }
  }

