.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .btn.active {
  background-color: var(--tempedge-dark-blue);
}
.pagination .btn {
  border-radius: 50px;
  margin: 0px 3px;
  background-color: var(--tempedge-dark-gray);
  color: white;
  cursor: pointer;
  padding-top: 8px;
  outline: none;
}

.pagination .btn-navigation {
  display: block;
  margin: 0px 3px;
  padding: 5px 30px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  line-height: 28px;
}

.pagination .btn-next {
  background-color: var(--tempedge-dark-blue);
}

.pagination .btn-previous {
  background-color: var(--tempedge-dark-gray);
}
