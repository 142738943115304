.tempedge-button {
  width: 100%;
  min-width: 30%;
  min-height: 3.8rem;
  color: white;
  border-radius: 1.6rem;
  border: none;
}

.tempedge-button:focus {
  outline: none;
}

.tempedge-button--square-left-side {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tempedge-button--square-right-side {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tempedge-button--state-primary {
  background-color: var(--tempedge-blue);
}
.tempedge-button--state-default {
  background-color: var(--tempedge-gray);
}

.tempedge-button--state-success {
  background-color: var(--tempedge-green);
}

.tempedge-button--state-danger {
  background-color: var(--tempedge-orange);
}

.tempedge-button--state-primary:disabled,
.tempedge-button--state-default:disabled,
.tempedge-button--state-success:disabled,
.tempedge-button--state-danger:disabled {
  opacity: 0.6;
}
