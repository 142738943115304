:root {
  /* top bar */
  --top-bg: var(--tempedge-blue);
  --top-colon-color: white;
  --top-text-color: #8eddfd;
  --top-selected-color: white;
  --top-meridiem-color: white;

  /* clock wrapper */
  --clock-wrapper-bg: white;
  --clock-bg: #f4f4f4;

  /* clock hand */
  --hand-line-color: var(--tempedge-blue);
  --hand-circle-center: var(--tempedge-blue);
  --hand-circle-outer: var(--tempedge-blue);
  --hand-minute-circle: var(--tempedge-blue); /* minutes only: dot between intervals */

  /* numbers */
  --numbers-text-color: black;
  --numbers-font-size-reg: 14px; /* 12h mode only */
  --numbers-font-size-inner: 15px; /* 24h mode only */
  --numbers-font-size-outer: 13px; /* 24h mode only */

  /* meridiem buttons */
  --meridiem-bg-color: white;
  --meridiem-text-color: black;
  --meridiem-selected-bg-color: var(--tempedge-blue);
  --meridiem-selected-text-color: white;

  /* done button */
  --done-bg-color: white;
  --done-text-color: var(--tempedge-blue);
  --done-border-top: 1px solid white;
  --done-font-size: 13px;
  --done-font-weight: 500;
}

.backdrop .react-timekeeper__done-button {
  font-weight: bold;
}

.backdrop .react-timekeeper-button-reset {
  margin-top: 16px;
}
