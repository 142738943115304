/* DownloadFile.css */
.download-file {
    display: flex;
    flex-direction: column;
  }
  
  .download-btn {
    background-color: var(--tempedge-green);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  