.react-calendar { 
  width: 800px !important;
  max-width: 100%;
  background-color: #fff;
  color: #222;  /*este color es el texto del los dias*/
  border-radius: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.125em;
  padding: 15px 0;
 }

 .calendar-user{
  width: 800px !important;
  max-width: 100%;
  color: #222;  /*este color es el texto del los dias*/
  border-radius: 1.5rem;
  border: var(--tempedge-gray);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.125em;
  padding: 2rem;
 }

 .react-calendar__navigation {
  display: flex;
  height: 95px !important;
  margin-bottom: 1em;
}
 .react-calendar__navigation button {
  color: #111013;
  min-width: 44px;
  background: none;
  font-size: 25px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }

 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: black;
  border-radius: 6px;
 }
 .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #3eb7e9;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #3eb7e9;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #3eb7e9;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: var(--tempedge-dark-blue);
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: var(--tempedge-light-blue);
  border-radius: 0;
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background:var(--tempedge-light-blue);
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background:var(--tempedge-light-blue);
  color: white;
 }

 .react-calendar__month-view{
  height: 300px;
 }
 .react-calendar__month-view__days{
  display: flex;
  flex-wrap: wrap;
  height: 270px;
 }

 .react-calendar__navigation__prev2-button{
  display: none !important;
 }
 
 .react-calendar__navigation__next2-button{
  display: none !important;
 }

 .react-calendar__tile--now {
  background: none !important;
  color: black;
 }

 .react-calendar__tile--active {
  background:var(--tempedge-gray-darken-2) !important;
  color: black;
}

.highlight {
  background:var(--tempedge-light-bar-bkg-color)!important;
  color:#111013;
}


.react-calendar__month-view__days__day--weekend:disabled{
  color: red !important;
  background-color: white !important;
}

.react-calendar__tile--disabled {
  background-color: white !important;
}

.selected_tempedge{
  background:var(--tempedge-light-orange)!important;
  color:black;
}
.selected_find_tempedge{
  background:var(--tempedge-green)!important;
  color:black;
} 