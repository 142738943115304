@media (min-width: 992px) {

    .modal-content {
      max-height: 90vh;
    }

    .modal-lg {
      width: 90%;
    }
}

@media (min-width: 768px) {
    .modal-xl {
      width: 98%;
      max-width:1360px;
    }
}