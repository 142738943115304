.tutorials-top-nav {
    background-color: white;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    width: 98%;
    text-align: left;
}

.tutorials-top-nav-items .tutorials-top-nav-link {
    color: #333;
    margin: 0 10px;
    border-radius: 0;
    display: inline-block;
    transition: all 0.3s ease;
    background-color: transparent;
    border-bottom: 2px solid transparent;
}

.tutorials-top-nav-items .tutorials-top-nav-link.active {
    color: #020202;
    background-color: transparent;
    border-bottom: 2px solid #5abeec;
}

.tutorials-top-nav-items .tutorials-top-nav-link:hover {
    background-color: #f1f1f1;
    color: #5abeec;
}

@media (min-width: 1024px) {
    .tutorials-top-nav {
      margin-left: -35px;
    }
  }