:root {
    --radiusSize : 1.6rem
}

.main-container {    
    display: inline-flex;
}

.common-container-sm {
    height: 28px;
    width: 26px;
    padding: 0;
    padding-left: 1px;
}

.main-container-error {
    border:1px solid red;
    padding: 0px;    
    border-radius: var(--radiusSize);
}

.common-container {    
    border : 0.1rem solid #ced4da;
    margin : 0;
    background-color: #fff;
    min-height: initial;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--tempedge-black);    
    font-size: 10px;
    font-weight: bold;    
    transition: initial;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: normal;
}

.common-container-md {
    min-height: 3.8rem;
    height: 36px;    
    width: 30px;
}

.common-container input{
    position: relative;
    display: block;
    opacity: 0;
    width: 0;
    height: 0;
}

.common-container:hover{
    background-color: var(--tempedge-blue);
    border: 1px solid var(--tempedge-blue);
    color: var(--tempedge-white);
}

.common-container-gray:hover{
    background-color: var(--tempedge-gray-darken-3);    
    color: var(--tempedge-white);    
    border: 1px solid var(--tempedge-gray-darken-3);    
    box-shadow: var(--tempedge-gray-darken-3);
}

.common-container-orange:hover{
    background-color: var(--tempedge-orange);    
    color: var(--tempedge-white);    
    border: 1px solid var(--tempedge-orange);    
    box-shadow: var(--tempedge-orange);
}

.common-container:focus, 
.common-container.focus,
.common-container.active, 
.common-container:active, 
.common-container:not(:disabled):not(:disabled):active {
    background-color: var(--tempedge-blue);
    border-color: var(--tempedge-blue);
    box-shadow: var(--tempedge-blue);
    color: #fff;
}

.common-container-gray:focus, 
.common-container-gray.focus,
.common-container-gray.active, 
.common-container-gray:active, 
.common-container-gray:not(:disabled):not(:disabled):active {
    background-color: var(--tempedge-gray-darken-3);
    border-color: var(--tempedge-gray-darken-3);
    box-shadow: var(--tempedge-gray-darken-3);
    color: #fff;
}

.common-container-orange:focus, 
.common-container-orange.focus,
.common-container-orange.active, 
.common-container-orange:active, 
.common-container-orange:not(:disabled):not(:disabled):active {
    background-color: var(--tempedge-orange);
    border-color: var(--tempedge-orange);
    box-shadow: var(--tempedge-orange);
    color: #fff;
}

.selected-blue {
    background-color: var(--tempedge-blue);    
    color: var(--tempedge-white);
}

.selected-orange {
    background-color: var(--tempedge-orange);    
    color: var(--tempedge-white);
}

.selected-gray{
    background-color: var(--tempedge-gray-darken-3);    
    color: var(--tempedge-white);
}

.left-container {    
    border-bottom-left-radius : var(--radiusSize);
    border-top-left-radius : var(--radiusSize);    
}

.left-container-md {
    max-width: 60px;
    width: 30px;
}

.right-container {     
    border-bottom-right-radius : var(--radiusSize);
    border-top-right-radius : var(--radiusSize);
}

.right-container-md {
    max-width: 60px; 
    width: 30px;
}