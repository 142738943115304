/* Initial configuration to component ---------------------------------------- */
.loader__loader-container {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader__spinners-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.loader__img-background {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.loader__loading-message {
  padding-top: 10px;
  font-size: 35px;
  color: #212f3c;
}

/* Loader animated components ---------------------------------------- */
.loader__spinners-container--loader-outside {
  width: 145px;
  height: 145px;
  border-top: 10px solid var(--tempedge-orange);
  border-right: 10px solid transparent;
  border-radius: 50%;
  animation-name: rotationColors;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  justify-content: center;
  position: absolute;
  border-radius: 50%;
}

.loader__spinners-container--loader-center {
  width: 160px;
  height: 160px;
  border-top: 10px solid var(--tempedge-green);
  border-left: 10px solid transparent;
  border-radius: 50%;
  animation-name: rotationColor_center;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  justify-content: center;
  position: absolute;
  border-radius: 50%;
}

.loader__spinners-container--loader-inside {
  width: 175px;
  height: 175px;
  border-bottom: 10px solid var(--tempedge-blue);
  border-left: 10px solid transparent;
  border-radius: 50%;
  animation-name: rotationColors;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  justify-content: center;
  position: absolute;
  border-radius: 50%;
}

/* Loader spinners animation ---------------------------------------- */
@keyframes rotationColors {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotationColor_center {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
