.tutorials-card-container {
    border: 1px solid #000;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.tutorials-card-video-container {
    margin: 0;
    width: 200px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 2px;
}

.tutorials-button-open-modal {
    margin: 0 auto;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.tutorials-play-icon-circle {
    margin: 0;
    padding-bottom: 5px;
    height: 50px;
    width: 350px;
    border-radius: 50%;
    color: #FF0000;
    font-size: 20px;
}

.tutorials-label-video-card {
    color: black;
    font-size: 11px;
}
