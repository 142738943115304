.time-entry {
 padding: 2rem;
 margin: 28px 0;
 border-radius: 1.5rem;
 box-shadow: inset 0 1px 6px 0 #b5b5b5;
}

.time-entry__header {
 padding-top: 1rem;
 padding-bottom: 2rem;
 border-top: 1px dashed var(--tempedge-gray);
}

.time-entry__body {
 border-top: 1px dashed var(--tempedge-gray);
 padding-top: 2rem;
}

.time-entry__body input.small {
 width: 70px;
}

#cellWeek {
 border-radius: 0;
 border-end-end-radius: 1.3rem;
 border-start-end-radius: 1.3rem;
}

/* .table-input-group td {
  padding: 0.4rem !important;
  margin-top: 0.3rem !important;
  text-align: center;
  text-justify: auto;
} */

.editableCell {
 text-align: center;
 padding: 30px 0px !important;
}

.disableds {
 background-color: #c7f8c7;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
 text-align: center;
 border-top-left-radius: 0;
 border-bottom-left-radius: 0;
}

.table-input-group td {
 padding: 0.5rem !important;
}

/* Estilos por defecto para la tabla */

/* nombre completo del día y la fecha en pantallas grandes */
.day-column .day-full,
.day-column .date {
 display: inline;
}

.column-full {
  text-align: center;
  width: 10%;
 }

.day-column .day-initial {
 display: none;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
 .table-header-left,
 .table-header-mid,
 .table-header-right {
  width: 20%;
 }

 .table-content {
  font-size: 0.8rem;
  padding: 5px;
 }

 .actions-cell__icon {
  width: 20px;
  height: 20px;
 }

 /* Ocultar el nombre completo del día y la fecha en pantallas pequeñas */
 .day-column .day-full,
 .day-column .date,
 .column-full {
  display: none;
 }
}

/* Media query para pantallas muy pequeñas */
@media (max-width: 480px) {
 .table-header-left,
 .table-header-mid,
 .table-header-right {
  width: 25%;
 }

 .table-content {
  font-size: 0.7rem;
  padding: 3px;
 }

 .actions-cell__icon {
  width: 15px;
  height: 15px;
 }

 /* Ocultar el nombre completo del día y la fecha en pantallas pequeñas */
 .day-column .day-full,
 .day-column .date,
 .column-full {
  display: none;
 }
}
