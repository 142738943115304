/* /* Estilo general de la tabla responsive */
.responsive-table {
   text-align: center;
   width: 100%;
   margin-bottom: 24px;
   border-spacing: 0;
   border-collapse: collapse;
   overflow-x: auto;
   border-top-left-radius: 1.6rem;
   border-top-right-radius: 1.6rem;
}

/* Estilo para la primera celda de cada fila, añade un borde izquierdo sólido */
.responsive-table tr td:first-child {
   border-left-style: solid;
   border-top-left-radius: 1.6rem;
   max-width: 170px;
}

.responsive-table .table-header-left-first {
   font-size: 1.4rem;
   font-weight: normal;
   font-style: normal;
   font-stretch: normal;
   border-right: 0.05px solid lightgray;
   border-top-left-radius: 1.6rem;
   background-color: #5abeec;
   color: #ffffff;
}

.responsive-table .table-header-mid-first {
   font-size: 1.4rem;
   font-weight: normal;
   font-style: normal;
   font-stretch: normal;
   background-color: #5abeec;
   color: #ffffff;
   max-width: 170px;
}

.responsive-table .table-header-right-first {
   font-size: 1.4rem;
   font-weight: normal;
   font-style: normal;
   font-stretch: normal;
   border-top-right-radius: 1.6rem;
   background-color: #5abeec;
   color: #ffffff;
   max-width: 170px;
}

.responsive-table .table-header-mid-first:last-child {
   font-size: 1.4rem;
   font-weight: normal;
   font-style: normal;
   font-stretch: normal;
   border-top-right-radius: 1.6rem;
   background-color: #5abeec;
   color: #ffffff;
   max-width: 170px;
}

.responsive-table thead {
   position: relative;
   clip: auto;
   height: auto;
   width: auto;
   overflow: auto;
}

.custom-totals {
   font-size: 11px;
   color: rgb(0, 0, 0);
   text-transform: uppercase;
   text-align: center;
   display: flex;
   margin: auto;
   justify-content: center;
   align-items: center;
}

.margin-botton-payroll {
   margin-top: 26px;
}

.custom-parrafo {
   font-size: 11px;
   color: rgb(0, 0, 0);
   text-transform: uppercase;
   text-align: center;
   margin: 0;
}

.name-table{
   font-size: 12px;
   color: rgb(0, 0, 0);
   text-transform: uppercase;
   text-align: center;
   font-weight: normal !important;
}

.line-separate-custom-payroll{
   border-bottom: dotted 1px #333333;
   margin-bottom: '15px';
   padding: '15px 0';
}

.title-form-payroll{
   font-size: 14px;
   color: rgb(0, 0, 0);
   text-transform: uppercase;
   text-align: left;
   font-weight: normal !important;
   margin: 0;
   margin-top: 8px;
}

.payroll-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;
 }
 
 .payroll-section {
   width: 90%; 
 }

 .total-colspan {
   border-bottom: dotted 1px #626262; 
   border-left: dotted 1px #626262; 
   padding: 10px; 
}
 
 .title-confir-custom-payroll {
   font-size: 11px;
   text-transform: uppercase;
   text-align: center !important;
   font-weight: normal !important;
   color: #000 !important;
   border: none;
   padding: 0.3rem 0;
   align-items: center;
   text-align: center;
   padding: 8px 4px;
   font-size: 9px;
   line-height: 12px;;
   color: var(--group-color);
   background: #f4f4f4;
   border: 1px solid var(--group-border);
   transition:
      background 0.3s ease,
      border 0.3s ease,
      color 0.3s ease;
 }
 
 .form-group-payroll {
   font-size: 11px;
   color: rgb(0, 0, 0);
   text-transform: uppercase;
   text-align: center;
   margin: 4px 0;
   display: flex ;
   justify-content: center;
 }
 
 .downIconConfirm {
   width: 8px;
   height: 5px;
   display: inline;
   margin-right: 8px;
 }
 
 .custom-parrafo {
   margin: 0 4px; /* Espacio entre los elementos */
 }

/* Estilo de los encabezados de la tabla */
.responsive-table thead th {
   background-color: #5abeec;
   border-bottom: 1px solid lightgray;
   font-weight: normal;
   text-align: center;
   color: white;
   max-width: 95%;
}

.downIconConfirm {
   width: 8px;
   height: 5px;
   display: "inline";
   margin-left: 19px;
 }

/* Estilo general para el cuerpo y celdas de la tabla */
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
   display: block;
   padding: 0;
   text-align: center;
   white-space: normal; /* Permite que el texto se divida en varias líneas si es necesario */
   max-width: 95%;
}

/* Estilo de padding para celdas de la tabla */
.responsive-table th,
.responsive-table td {
   padding: 6px;
   vertical-align: middle;
}

/* Ajuste de las filas del cuerpo de la tabla */
.responsive-table tbody tr {
   margin-bottom: 8px;
}

/* Alineación del contenido de celdas con tipo de datos 'moneda' esto util para los input de los días de la semana */
.responsive-table tbody td[data-type='currency'] {
   text-align: center; /* Alinea el texto a la derecha para celdas que contienen valores de moneda */
   max-width: 95%;
}

.responsive-table thead {
   position: relative;
   clip: auto;
   height: auto;
   width: auto;
   overflow: auto;
}

.responsive-table tr {
   display: table-row;
}

.responsive-table th,
.responsive-table td {
   display: table-cell;
   padding: 6px;
   max-width: 190px;
}

.responsive-table tbody {
   display: table-row-group;
}

.responsive-table tbody tr {
   display: table-row;
   border-width: 1px;
}

.responsive-table tbody tr:nth-of-type(even) {
   background-color: rgba(0, 0, 0, 0.12);
}

.responsive-table tbody th[scope='row'] {
   background-color: transparent;
   color: #000001;
   text-align: center;
   font-weight: normal;
}

.responsive-table tbody td {
   border: 1px solid lightgray;
   text-align: center;
}

.responsive-table tbody th[scope='row'] {
   border-left: 1px solid lightgray;
   border-bottom: 1px solid lightgray;
   font-weight: normal;
}

.responsive-table tbody td[data-title]:before {
   content: none;
}
.responsive-table .time-sheet-row-responsive {
   display: table-row;
}

.show-full {
   text-overflow: clip;
   white-space: normal;
   overflow: visible;
}

.time-sheet-row--selected {
   background-color: #fffbd3 !important;
 }
 
 .time-sheet-row--selected > td {
   font-weight: 500 !important;
 }
 
 .time-sheet-row .text-danger {
   color: var(--error-result-bar-font-color);
 }
 

@media (max-width: 1024px) {
   .subtitle-payroll {
      font-size: 11px;
   }

   /* Muestra solo la inicial del día */
   .responsive-table thead th span {
      display: none;
   }

   .day-blanck{
      width: 60px;
   }

   .responsive-table thead th::before {
      content: attr(data-initial);
      display: block;
      font-size: 10px;
   }

   /* aqui el font-size de la tabla */
   .responsive-table .table-header-left-first {
      font-size: 10px;
   }
   .responsive-table .table-header-mid-first {
      font-size: 10px;
   }
   .responsive-table .table-header-right-first {
      font-size: 10px;
   }
   .day-column{
      font-size: 10px;
   }

   .responsive-table tbody th[scope='row'] {
      font-size: 10px;
      font-weight: normal;
   }

   .custom-totals {
      font-size: 11px;
      color: rgb(0, 0, 0);
      text-transform: uppercase;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;
      align-items: center;
   }
}

@media (max-width: 920px) {
   .responsive-table thead th span {
      display: none;
   }

   .responsive-table tbody td .day-column {
      text-align: right;
      font-size: 10px;
   }

   .responsive-table thead th::before {
      content: attr(data-initial);
      display: block;
      font-size: 10px;
   }
}
/* Estilo de la tabla en pantallas pequeñas */
@media (max-width: 700px) {
   /* Estilo de los encabezados de la tabla */
   .responsive-table thead th {
      background-color: #5abeec;
      border: 1px solid lightgray;
      font-weight: normal;
      text-align: center;
      color: white;
   }

   /* Estilo general para el cuerpo y celdas de la tabla */
   .responsive-table tbody,
   .responsive-table tr,
   .responsive-table th,
   .responsive-table td {
      display: block;
      padding: 0;
      text-align: center;
      white-space: normal; /* Permite que el texto se divida en varias líneas si es necesario */
      width: 100%;
   }
   /* Alineación del contenido de las celdas del cuerpo de la tabla */
   .responsive-table tbody td {
      text-align: right;
   }

   .day-blanck{
      width:100%;
   }

   /* Oculta el encabezado de la tabla para accesibilidad en pantallas pequeñas */
   .responsive-table thead {
      position: absolute;
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;
   }
   
   /* Alineación del contenido de las celdas del cuerpo de la tabla */
   .responsive-table tbody td {
      text-align: center;
      font-size: 10px;
   }

   /* Alineación del contenido de celdas con tipo de datos 'moneda' esto util para los input de los días de la semana */
   .responsive-table tbody td[data-type='currency'] {
      text-align: center; /* Alinea el texto a la derecha para celdas que contienen valores de moneda */
      max-width: 95%;
   }
   /* Estilo para el título de celdas del cuerpo de la tabla */
   .responsive-table tbody td[data-title]:before {
      content: attr(data-title); /* Muestra el valor de data-title antes del contenido de la celda en cards */
      float: left;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
      text-transform: uppercase;
      width: 50%;
      text-align: left;
   }
   .responsive-table tbody td[data-title].day-blanck:before {
      width: 100%;
   }

   .subtitle-payroll {
      font-size: 12px;
      text-transform: uppercase;
      margin: 10px 8px 0 0;
      width: auto;
      text-align: right;
   }

   /* Estilo de las filas del cuerpo de la tabla con alcance de fila esto cuando es pequeña la pantalla y se vuelve una card */
   .responsive-table tbody th[scope='row'] {
      background-color: #5abeec;
      color: white;
      margin: 8px 0;
   }
   /* Alineación del contenido de celdas con tipo de datos 'moneda' esto util para los input de los días de la semana */
   .responsive-table tbody td[data-type='currency'] {
      text-align: center;
      max-width: 95%;
   }

   .day-column {
      border-bottom: #070808 1px dashed;
      margin: 20px 0px;
   }

   /* Quita el margen inferior de la última fila del cuerpo de la tabla */
   .responsive-table tbody tr:last-of-type {
      margin-bottom: 0;
   }

   .responsive-table tbody tr {
      margin-bottom: 16px;
      display: block;
   }
   .responsive-table tbody td {
      border: none;
      text-align: center;
   }
   /* Alineación del contenido de las celdas del cuerpo de la tabla */
   .responsive-table tbody td {
      text-align: right;
   }
}

@media (max-width: 700px) {
   .responsive-table {
      overflow-x: hidden; /* Evitar el desplazamiento horizontal */
  }

  .responsive-table,
    .responsive-table tbody,
    .responsive-table tr,
    .responsive-table th,
    .responsive-table td {
        margin: 0;
        padding: 0 4px;
        box-sizing: border-box; /* Asegurar que padding y borde se incluyan en el ancho */
    }

  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table th,
  .responsive-table td {
      display: table; /* Para mantener la estructura de tabla */
      width: 100%;
  }
   .responsive-table,
   .responsive-table tr,
   .responsive-table th,
   .responsive-table td {
       width: 100%;
   }
   .responsive-table tbody td[data-title]:before {
       width: 100%; /* Para asegurarte que el título de las celdas ocupe todo el ancho */
   }
}